<template>
  <div class="sku">
    <div class="sku-main">
      <div v-if="sku1.length !=0 ">
        <div class="sku-titles">Size</div>
        <div class="sku-con">
          <div v-for="(item,index) in sku1" :key="index" class="sku-list" :class="{'actived': item.checked,'disabled':item.disabled}" @click="handSku1(item,index)">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="sku2.length !=0 ">
        <div class="sku-titles">Color</div>
        <div class="sku-con">
          <div v-for="(item,index) in sku2" :key="index" class="sku-color-li" :class="{'actived': item.checked,'disabled':item.disabled}" @click="handSku2(item,index)">
            <span class="sku-color" :style="{'background-color': item.option.color}" />
          </div>
        </div>
      </div>
      <div v-if="sku3.length !=0 ">
        <div class="sku-titles">image</div>
        <div class="sku-con">
          <div v-for="(item,index) in sku3" :key="index" class="sku-list" :class="{'actived': item.checked,'disabled':item.disabled}" @click="handSku3(item,index)">
            <img :src="item.option.fileUrl">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    skuMaps: {
      type: Array,
      default: () => []
    },
    skuInfos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sku1: [],
      sku2: [],
      sku3: []
      // sku1: [{ name: 's', ids: [11855, 11857], disabled: false, checked: false }, { name: 'x', ids: [11455, 11860], disabled: false, checked: false }, { name: 'xl', ids: [11856], disabled: false, checked: false }],
      // sku2: [{ name: 'green', ids: [11856, 11857], disabled: false, checked: false }, { name: 'red', ids: [11455, 11855, 11860], disabled: false, checked: false }],
      // sku3: [{ name: 'dh', ids: [11455, 11855, 11856, 11857] }, { name: 'dw', ids: [11860] }]
    }
  },
  computed: {
    // 获取选中的sku
  },
  watch: {
    skuMaps(newValue, oldValue) {
      for (const item of newValue) {
        if (item.type === 1) {
          for (const item2 of item.items) {
            item2.disabled = false
            item2.checked = false
            item2.option = JSON.parse(item2.option)
            this.sku1.push(item2)
          }
        }
        if (item.type === 2) {
          for (const item2 of item.items) {
            item2.disabled = false
            item2.checked = false
            item2.option = JSON.parse(item2.option)
            this.sku2.push(item2)
          }
        }
        if (item.type === 3) {
          for (const item2 of item.items) {
            item2.disabled = false
            item2.checked = false
            item2.option = JSON.parse(item2.option)
            this.sku3.push(item2)
          }
        }
      }
    }
  },
  created() {
    if (this.skuMaps) {
      for (const item of this.skuMaps) {
        if (item.type === 1) {
          for (const item2 of item.items) {
            item2.disabled = false
            item2.checked = false
            item2.option = JSON.parse(item2.option)
            this.sku1.push(item2)
          }
        }
        if (item.type === 2) {
          for (const item2 of item.items) {
            item2.disabled = false
            item2.checked = false
            item2.option = JSON.parse(item2.option)
            this.sku2.push(item2)
          }
        }
        if (item.type === 3) {
          for (const item2 of item.items) {
            item2.disabled = false
            item2.checked = false
            item2.option = JSON.parse(item2.option)
            this.sku3.push(item2)
          }
        }
      }
    }
  },
  methods: {
    handSku1(item, index) {
      let ids2 = []
      let ids3 = []
      if (item.disabled) {
        return
      }
      if (this.sku1[index].checked) {
        this.$set(this.sku1[index], 'checked', false)
        this.selectSku(true)
      } else {
        this.$set(this.sku1[index], 'checked', true)
        this.selectSku(false)
        if (this.sku2.length > 0) {
          ids2 = this.getFirstIds(this.sku2)
        }
        if (this.sku3.length > 0) {
          ids3 = this.getFirstIds(this.sku3)
        }
        this.searchTid(this.sku1[index].ids, ids2, ids3)
      }
      // this.sku1[index].checked = !this.sku1[index].checked
      // 获取第一个属性的可选中sku
      const selectSku = this.getSku1Ids()
      // 设置不可选中
      this.sku2 = this.setSkuDisabled(selectSku, this.sku2)
      this.sku3 = this.setSkuDisabled(selectSku, this.sku3)
      // 取消选中
      this.sku2 = this.canCelSku(selectSku, this.sku2)
      this.sku3 = this.canCelSku(selectSku, this.sku3)
      this.$forceUpdate()
    },
    handSku2(item, index) {
      let ids1 = []
      let ids3 = []
      if (item.disabled) {
        return
      }
      // this.sku2[index].checked = !this.sku2[index].checked
      if (this.sku2[index].checked) {
        this.$set(this.sku2[index], 'checked', false)
        this.selectSku(true)
      } else {
        this.$set(this.sku2[index], 'checked', true)
        this.selectSku(false)
        if (this.sku1.length > 0) {
          ids1 = this.getFirstIds(this.sku1)
        }
        if (this.sku3.length > 0) {
          ids3 = this.getFirstIds(this.sku3)
        }
        this.searchTid(this.sku2[index].ids, ids1, ids3)
      }
      const selectSku = this.getSku2Ids()
      // 有第三种属性的话要把sku2和sku3的数组合并
      this.sku1 = this.setSkuDisabled(selectSku, this.sku1)
      this.sku3 = this.setSkuDisabled(selectSku, this.sku3)
      // 取消选中
      this.sku1 = this.canCelSku(selectSku, this.sku1)
      this.sku3 = this.canCelSku(selectSku, this.sku3)
      this.$forceUpdate()
    },
    handSku3(item, index) {
      let ids1 = []
      let ids2 = []
      if (item.disabled) {
        return
      }
      // this.sku3[index].checked = !this.sku3[index].checked
      if (this.sku3[index].checked) {
        this.$set(this.sku3[index], 'checked', false)
        this.selectSku(true)
      } else {
        this.$set(this.sku3[index], 'checked', true)
        this.selectSku(false)
        if (this.sku1.length > 0) {
          ids1 = this.getFirstIds(this.sku1)
        }
        if (this.sku2.length > 0) {
          ids2 = this.getFirstIds(this.sku2)
        }
        this.searchTid(this.sku3[index].ids, ids1, ids2)
      }
      const selectSku = this.getSku3Ids()
      // 有第三种属性的话要把sku2和sku3的数组合并
      this.sku1 = this.setSkuDisabled(selectSku, this.sku1)
      this.sku2 = this.setSkuDisabled(selectSku, this.sku2)
      // 取消选中
      this.sku1 = this.canCelSku(selectSku, this.sku1)
      this.sku2 = this.canCelSku(selectSku, this.sku2)
      this.$forceUpdate()
    },
    // 获取第一个选中的属性
    getFirstIds(arr) {
      let num = 0
      let ids = ''
      // 获取选中颜色得option，另外一个思路根据tid取重复的
      if (arr.length > 0) {
        for (const item of arr) {
          if (item.checked) {
            num += 1
            ids = item.ids
          }
        }
      }
      if (num === 0) {
        ids = JSON.stringify(arr[0].ids)
      }
      return ids
    },
    searchTid(sku1Ids, sku2Ids, sku3Ids) {
      // 找到相同的ids
      const common = sku1Ids.filter((value) => {
        if (sku2Ids.length === 0 && sku3Ids.length === 0) {
          return sku1Ids[0]
        } else if (sku2Ids.length === 0) {
          return sku3Ids.includes(value)
        } else if (sku3Ids.length === 0) {
          return sku2Ids.includes(value)
        } else {
          return sku2Ids.includes(value) && sku3Ids.includes(value)
        }
      })
      for (const item of this.skuInfos) {
        if (item.id === common[0]) {
          this.$emit('onSkuChange', item.tid)
        }
      }
      console.log('set...', common)
    },
    // 参数1可选的sku,参数2要设置的sku
    setSkuDisabled(selectSku, sku) {
      // console.log('selectSku...', selectSku)
      // console.log('sku...', sku)
      // for (const item of sku) {
      //   // 先全部设置不可选中
      //   item.disabled = true
      //   for (const itemIds of item.ids) {
      //     // 可选的sku包含再其中就设置成可选中
      //     console.log('selectSku...', selectSku, '....itemIds,', itemIds)
      //     if (selectSku.includes(itemIds)) {
      //       item.disabled = false
      //       break
      //     }
      //   }
      // }
      // console.log(sku)
      // return sku
      return sku
    },
    // 参数1可选的sku,参数2要设置的sku
    canCelSku(selectSku, sku) {
      // for (const item of sku) {
      //   let num = 0
      //   for (const itemIds of item.ids) {
      //     // 可选的sku包含再其中就设置成可选中
      //     if (selectSku.includes(itemIds)) {
      //       num += 1
      //     }
      //   }
      //   if (num === 0) {
      //     item.checked = false
      //   }
      // }
      // console.log('取消选中...', sku)
      return sku
    },
    // 获取第一个sku选中的所有ids
    getSku1Ids() {
      let arr = []
      this.sku1.forEach(item => {
        if (item.checked) {
          arr = [...arr, ...item.ids]
        }
      })
      return arr
    },
    // 获取第二个sku选中的所有ids
    getSku2Ids() {
      let arr = []
      this.sku2.forEach(item => {
        if (item.checked) {
          arr = [...arr, ...item.ids]
        }
      })
      return arr
    },
    // 获取第三个sku选中的所有ids
    getSku3Ids() {
      let arr = []
      this.sku3.forEach(item => {
        if (item.checked) {
          arr = [...arr, ...item.ids]
        }
      })
      return arr
    },
    selectSku(isCancel) {
      const arr = []
      const arr2 = []
      const arr3 = []
      let num = 0
      let num2 = 0
      let num3 = 0
      if (this.sku1.length > 0) {
        for (const item of this.sku1) {
          if (item.checked) {
            num += 1
            arr.push(...item.ids)
          }
        }
        // 如果啥都没选就默认全选中
        if (num === 0) {
          for (const item of this.sku1) {
            arr.push(...item.ids)
          }
        }
      }
      if (this.sku2.length > 0) {
        for (const item of this.sku2) {
          if (item.checked) {
            num2 += 1
            arr2.push(...item.ids)
          }
        }
        // 如果啥都没选就默认全选中
        if (num2 === 0) {
          for (const item of this.sku2) {
            arr2.push(...item.ids)
          }
        }
      }
      if (this.sku3.length > 0) {
        for (const item of this.sku3) {
          if (item.checked) {
            num3 += 1
            arr3.push(...item.ids)
          }
        }
        // 如果啥都没选就默认全选中
        if (num3 === 0) {
          for (const item of this.sku3) {
            arr3.push(...item.ids)
          }
        }
      }
      console.log('selectSku....', arr)
      console.log('selectSku2....', arr2)
      console.log('selectSku3....', arr3)
      const result = []
      if (arr.length > 0) {
        console.log('arr.length1....')
        result.push(...arr.filter((value) => {
          if (arr2.length > 0 && arr3.length > 0) {
            return arr2.includes(value) && arr3.includes(value)
          } else if (arr2.length > 0 && arr3.length === 0) {
            return arr2.includes(value)
          } else if (arr2.length === 0 && arr3.length > 0) {
            return arr3.includes(value)
          }
        }))
      } else if (arr2.length > 0) {
        console.log('arr.length2....')
        result.push(...arr.filter((value) => {
          if (arr.length > 0 && arr3.length > 0) {
            return arr.includes(value) && arr3.includes(value)
          } else if (arr.length > 0 && arr3.length === 0) {
            return arr.includes(value)
          } else if (arr.length === 0 && arr3.length > 0) {
            return arr3.includes(value)
          }
        }))
      } else if (arr3.length > 0) {
        console.log('arr.length3....')
        result.push(...arr.filter((value) => {
          if (arr.length > 0 && arr2.length > 0) {
            return arr.includes(value) && arr2.includes(value)
          } else if (arr.length > 0 && arr2.length === 0) {
            return arr.includes(value)
          } else if (arr.length === 0 && arr2.length > 0) {
            return arr2.includes(value)
          }
        }))
      }
      const array = []
      console.log('....skuInfos....', this.skuInfos)
      for (const items of this.skuInfos) {
        for (const item2 of result) {
          if (items.id === item2) {
            array.push(items.tid)
          }
        }
      }
      this.$emit('setSkuArr', array)
      console.log('选中的sku....', array)
      if (isCancel) {
        this.$emit('onSkuChange', array[0])
      }

      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
.sku {
  .sku-main {
    padding: 0 20px;
  }
  &-titles {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .disabled{
    cursor: no-drop !important;
  }
  .sku-con {
    display: flex;
    flex-wrap: wrap;
    .sku-list {
      display: inline-block;
      padding: 8px;
      border: 1px solid #bababa;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 10px;
      margin-top: 12px;
      cursor: pointer;
      img{
        width: 20px;
        height: 20px;
      }
      &.actived {
        border: 1px solid #333;
        color: #333;
      }
    }
    .sku-color-li{
      width: 28px;
      height: 28px;
      border: 2px solid #fff;
      border-radius: 100px;
      display: inline-block;
      margin-right: 8px;
      &.actived{
        border: 2px solid #333;
      }
    }
    .sku-color {
      width: 20px;
      height: 20px;
      // background: #777777;
      border-radius:100px;
      margin-right: 10px;
      display: inline-block;
      position: relative;
      top: 2px;
      left: 2px;
      border: 2px solid #CBCBCB;
    }
  }
  .more {
    font-size: 12px;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 11px;
    padding-bottom: 8px;
    text-align: center;
    color: #777;
  }
  .down{
    img{
      width: 16px;
      height: 12px;
    }
    span{
      margin-left: 5px;
      color: #2260B5;
    }
  }
}
</style>
