<template>
  <div>
    <div class="font-title">Text</div>
    <div class="font">
      <div class="font-size">
        <div class="font-size-select">
          <a-input v-model="fontSize" style="width: 80px" @click="isShowFontSizeSelect = !isShowFontSizeSelect">
            <a-icon slot="suffix" type="down" style="color: rgba(0,0,0,.45)" @click="isShowFontSizeSelect = !isShowFontSizeSelect" />
          </a-input>
          <div v-if="isShowFontSizeSelect" style="width: 100px" class="select">
            <div v-for="(item,index) in fontSizeList" :key="index" class="select-li" @click="handleFontSizeChange(item.value)">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="font-box">
          <!-- <colorPicker v-model="color" /> -->
          <input id="" v-model="color" type="color" name="" class="colorpick" @input="setFontColor">
        </div>
        <a-tooltip placement="bottom">
          <template slot="title">
            Align Left
          </template>
          <div class="font-box" @click="handAlign('left')">
            <img src="@/assets/images/custom/zuo.png" alt="">
          </div>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template slot="title">
            Align Center
          </template>
          <div class="font-box" @click="handAlign('center')">
            <img src="@/assets/images/custom/zhong.png" alt="">
          </div>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template slot="title">
            Align Right
          </template>
          <div class="font-box" @click="handAlign('right')">
            <img src="@/assets/images/custom/you.png" alt="">
          </div>
        </a-tooltip>
      </div>
      <a-input v-model="fontFamily" @click="isShowSelect = !isShowSelect">
        <a-icon slot="suffix" type="down" style="color: rgba(0,0,0,.45)" />
      </a-input>
      <div v-if="isShowSelect" class="select">
        <div v-for="(item,index) in fontFamilyList" :key="index" class="select-li" @click="handFontFamily(item.value)">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sdk: {
      type: Object,
      default: () => ({})
    },
    activeInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isShowSelect: false,
      change: {
        horizontal: '',
        vertical: ''
      },
      color: '',
      fontFamily: 'Ambambo',
      fontSize: '30',
      fontSizeList: [{
        label: '12px',
        value: '12'
      }, {
        label: '14px',
        value: '12'
      }, {
        label: '16px',
        value: '16'
      }, {
        label: '18px',
        value: '18'
      }, {
        label: '20px',
        value: '20'
      }, {
        label: '22px',
        value: '22'
      }, {
        label: '24px',
        value: '24'
      }, {
        label: '26px',
        value: '26'
      }, {
        label: '28px',
        value: '28'
      }, {
        label: '30px',
        value: '30'
      }, {
        label: '32px',
        value: '32'
      }, {
        label: '34px',
        value: '34'
      }, {
        label: '36px',
        value: '36'
      }, {
        label: '38px',
        value: '38'
      }, {
        label: '40px',
        value: '40'
      }],
      fontFamilyList: [{
        label: 'Ambambo',
        value: 'Ambambo'
      }, {
        label: 'Arial',
        value: 'Arial'
      }, {
        label: 'Badaboom',
        value: 'Badaboom'
      }, {
        label: 'Bebas Neue',
        value: 'Bebas Neue'
      }, {
        label: 'Biko',
        value: 'Biko'
      }, {
        label: 'Black Board',
        value: 'Black Board'
      }, {
        label: 'blowbrush',
        value: 'blowbrush'
      }, {
        label: 'Burton slab',
        value: 'Burton slab'
      }, {
        label: 'calgary',
        value: 'calgary'
      }, {
        label: 'Century Gothic',
        value: 'Century Gothic'
      }],
      isShowFontSizeSelect: false
    }
  },
  watch: {
    color(newValue, oldValue) {
      if (newValue) {
        this.sdk.sarto.updateFontColor(newValue)
      }
    }
  },
  methods: {
    handAlign(val) {
      this.sdk.sarto.updateTextAlign(val)
    },
    handleFontSizeChange(val) {
      this.fontSize = val
      this.isShowFontSizeSelect = false
      this.sdk.sarto.updateFontSize(val)
    },
    handFontFamily(val) {
      this.fontFamily = val
      this.isShowSelect = false
      this.sdk.sarto.updateSDKFontFamily(val, () => {

      })
    },
    setFontColor(val) {
      this.sdk.sarto.updateFontColor(this.bgColor)
      // const bg = this.bgColor
      // this.sdk.sarto.setBackground(bg)
    }
  }
}
</script>
<style>
.font .ant-tooltip-inner{
  background-color: white !important;
  color: #fff !important;
}
.m-colorPicker .colorBtn{
  width: 24px !important;
  height: 24px !important;
  /* background-color: var(--font-color) !important; */
}
.ant-select-selection--single{
  background-color: white !important;
  border: 1px solid #D9D9D9 !important;
}
.image-opera .ant-input{
  background-color: white !important;
  border: 1px solid #D9D9D9 !important;
}
</style>
<style scoped lang="scss">
.select{
  margin-top: 16px;
  height: 300px;
  overflow-y: scroll;
  &-li{
    width: 243px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    margin-bottom: 4px;
    font-size: 12px;
    cursor: pointer;
    &:hover{
      background-color: #FFF4F4;
      color: #FF5347;
    }
  }
}
.image-opera {
  &-scale {
    display: flex;
    padding-right: 26px;
    margin-top: 18px;
    margin-bottom: 20px;
    &-list {
      &-top {
        color: #bababa;
        font-size: 12px;
      }
      &-box {
        width: 121px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #777777;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding-left: 6px;
        box-sizing: border-box;
        &-btn{
          width: 30px;
          height: 30px;
          margin-left: auto;
          img{
            width: 26px;
            height: 14px;
            display: block;
          }
        }
        &-img {
          width: 24px;
          height: 24px;
        }
        input {
          outline: none;
          margin-left: 5px;
          text-align: center;
          width: 40px;
        }
        &-color {
          width: 24px;
          height: 24px;
          background: #333333;
          border-radius: 4px;
        }
        &-bs {
          margin-left: auto;
          cursor: ew-resize;
          img {
            padding-right: 6px;
          }
        }
      }
    }
  }
}
.font-title{
  margin: 0px 29px;
  margin-top: 15px;
  color: #bababa;
  font-size: 12px;
}
.font{
  margin: 6px 29px;
  &-size{
    display: flex;
    margin-bottom: 14px;
    &-select{
      display: flex;
      flex-direction: column;
      position: relative;
      .select{
        position: absolute;
        top: 31px;
        z-index: 10;
        width: 100px;
      }
      .select-li{
        width: 80px;
      }
    }
  }
  &-box{
    width: 36px;
    height: 36px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #D9D9D9;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    cursor: pointer;
    img{
      width: 36px;
      height: 36px;
    }
  }
}
</style>
