<template>
  <div>
    <div class="add-image">
      <div class="contral">
        <div class="contral-list cur" @click="upImageDialog = true">
          <img src="@/assets/images/custom/addimg.png" alt="">
          <span>Add Image</span>
        </div>
        <div class="contral-list cur" style="margin-left: 12px" @click="addFont">
          <img src="@/assets/images/custom/addtext.png" alt="">
          <span>Add Text</span>
        </div>
      </div>
      <div class="image-main">
        <div class="image-header" @click="isCollapsed = !isCollapsed">
          Layer set
          <!-- <img src="@/assets/images/custom/xia_jiantou.png" :style="isCollapsed ? '' : 'transform: rotate(180deg)'" alt=""> -->
        </div>
        <transition name="collapse">
          <div v-if="isCollapsed" class="image-con">
            <div v-for="(item,index) in layers" :key="index">
              <div v-if="item.type == 'image'" class="image-list">
                <div class="image-list-left">
                  <img :src="item.sds.originUrl" alt="">
                </div>
                <div class="image-list-center">
                  <div class="image-list-name text-ellipsis" :class="designInfo.index === item.id ? 'red' : 'black' ">
                    {{ item.sds.localFileName }}
                  </div>
                </div>
                <div class="image-list-icon">
                  <img v-if="designInfo.index === item.id" src="@/assets/images/sds/tucengshang.png" alt="" @click.stop="handUp">
                  <img v-if="designInfo.index === item.id" src="@/assets/images/sds/tucengxia.png" alt="" @click.stop="handDown">
                  <img v-if="designInfo.index === item.id" src="@/assets/images/custom/shanchu.png" alt="" @click.stop="handRemove">
                  <img :src="item.isHide ? require('@/assets/images/sds/biyan.png') : require('@/assets/images/custom/eyes.png')" alt="" @click.stop="handHide(item.isHide,item.id)">
                </div>
              </div>
              <div v-if="item.type == 'i-text'" class="image-list">
                <div class="image-list-left">
                  <img src="@/assets/images/sds/ziti.png" alt="">
                </div>
                <div class="image-list-center">
                  <div class="image-list-name text-ellipsis" :class="designInfo.index === item.id ? 'red' : 'black' ">
                    {{ item.text }}
                  </div>
                </div>
                <div class="image-list-icon">
                  <img v-if="designInfo.index === item.id" src="@/assets/images/sds/tucengshang.png" alt="" @click.stop="handUp">
                  <img v-if="designInfo.index === item.id" src="@/assets/images/sds/tucengxia.png" alt="" @click.stop="handDown">
                  <img v-if="designInfo.index === item.id" src="@/assets/images/custom/shanchu.png" alt="" @click.stop="handRemove">
                  <img :src="item.isHide ? require('@/assets/images/sds/biyan.png') : require('@/assets/images/custom/eyes.png')" alt="" @click.stop="handHide(item.isHide,item.id)">
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <maskDialog :show="upImageDialog">
      <div class="up-image-dialog">
        <div class="up-close" @click="upImageDialog=false">
          <a-icon type="close" style="fontSize: 22px;" />
        </div>
        <div class="up-con">
          <a-upload class="upload-demo" :show-upload-list="false" :custom-request="uploadImage" accept=".jpg, .jpeg, .png" action>
            <div class="up">
              <div class="up-icon">
                <img v-if="!isUpload" src="@/assets/images/sds/ups.png" alt="">
                <a-spin v-if="isUpload" size="large" />
              </div>
              <p>Click or drag the local image here, upload it successfully, and save it to "My Materials"</p>
            </div>
          </a-upload>
          <div class="dots">
            <div class="dots-li">
              <span />Supports jpg, jpeg, png</div>
            <div class="dots-li"><span />The image size should not exceed 50M</div>
          </div>
          <div>
            <a-checkbox :checked="checked">
              When uploading pictures and text, there should be no illegal acts involving politics, pornography, infringement and so on. Serious cases will be banned and dealt with. Thank you for your cooperation, I have asked and read and agreed.
            </a-checkbox>
          </div>
        </div>
      </div>
    </maskDialog>
  </div>
</template>
<script>
import { getFileSize } from '@/utils/util.js'
import OSS from 'ali-oss'
import uuid from 'licia/uuid'
import { submitOssFile } from '@/api/file'
import maskDialog from '../components/mask.vue'
export default {
  components: {
    maskDialog
  },
  props: {
    sdk: {
      type: Object,
      default: () => ({})
    },
    // oss信息
    ossData: {
      type: Object,
      default: () => ({})
    },
    designInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      layers: [],
      isCollapsed: true,
      // 选中的图片ID
      selectImageId: '',
      upImageDialog: false,
      checked: true,
      isUpload: false
    }
  },
  watch: {
    'designInfo.layers': {
      handler(newValue, oldValue) {
        if (newValue) {
          newValue.forEach((item, index) => {
            item.id = index
          })
          this.layers = newValue.slice().reverse()
        }
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    handUp() {
      this.sdk.sarto.up()
    },
    handDown() {
      this.sdk.sarto.down()
    },
    handRemove() {
      this.sdk.sarto.delete()
    },
    handHide(isHide, index) {
      console.log('isHide....', this.isHide)
      console.log('this.designInfo.index....', index)

      if (isHide) {
        this.sdk.sarto.showByIndex(index)
        this.designInfo.layers[index].isHide = false
      } else {
        this.sdk.sarto.hideByIndex(index)
        this.designInfo.layers[index].isHide = true
      }
    },
    // 上传文件
    async uploadImage(option) {
      if (!this.checked) {
        // this.$notification.error({ message: '请先勾选' })
        return
      }
      if (option.file.size > 80 * 1024 * 1024) {
        this.$notification.error({ message: 'Maximum file size 80 MB' })
        return
      }
      this.isUpload = true
      console.log('option....', option)
      console.log('this.ossData....', this.ossData)
      try {
        const fileSize = getFileSize(option.file.size)
        const client = new OSS({
          endpoint: this.ossData.ossEndpoint,
          accessKeyId: this.ossData.accessKeyId,
          accessKeySecret: this.ossData.accessKeySecret,
          bucket: this.ossData.bucket,
          stsToken: this.ossData.securityToken
        })

        const file = option.file
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        const fileName = file.name.substr(0, point)
        const uuidValue = uuid()
        const fileNames = `${this.ossData.ossObjectNamePrefix}${uuidValue}${suffix}`

        const res = await client.multipartUpload(fileNames, file, {
          progress: async function(p) {
            console.log(p)
            console.log('分片。。。。。')
          },
          headers: {
            'Cache-Control': 'public, max-age=360000'
          }
        })

        if (res.res.statusCode === 200) {
          const imageUrl = this.ossData.domain + '/' + fileNames
          const imageInfo = await this.processImage(file)
          const { width, height } = imageInfo

          await this.submitFile(
            fileSize,
            fileName,
            width,
            height,
            imageUrl,
            res.bucket,
            res.name
          )
        } else {
          this.$message.error('Image upload failed due to unstable network')
          this.isUpload = false
        }
      } catch (error) {
        this.$message.error('Image upload failed due to unstable network')
        this.isUpload = false
      }
    },
    // 读取图片信息
    async processImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function(evt) {
          const image = new Image()
          image.onload = function() {
            resolve({
              width: image.width,
              height: image.height,
              imageUrl: evt.target.result
            })
          }
          image.onerror = function(error) {
            reject(error)
          }
          image.src = evt.target.result
        }
        reader.onerror = function(error) {
          reject(error)
        }
        reader.readAsDataURL(file)
      })
    },
    async submitFile(
      fileSize,
      fileName,
      width,
      height,
      fileUrl,
      bucketName,
      objectName
    ) {
      const { code, data } = await submitOssFile({
        fileSize: fileSize,
        fileName: fileName,
        fileType: 4,
        width: width,
        height: height,
        fileUrl: fileUrl,
        bucketName: bucketName,
        objectName: objectName
      })

      if (code === 200) {
        this.sdk.addMaterialById(data)
        this.isUpload = false
        this.upImageDialog = false
        this.$message.success('successfully')
        this.$emit('update:loading', false)
        this.$emit('resetImage')
      }
    },
    // 添加文字
    addFont() {
      this.sdk.sarto.addText()
    },
    // 删除图片
    delImageDialog(name, id) {
      this.selectImageId = id
      this.$refs.deleteImageDialog.deleteImage(name)
    }

  }
}
</script>
<style>
.up .ant-spin-dot-item{
  background-color: #FF5347;
}
</style>
<style scoped lang="scss">
.red{
  color: #FF5347;
}
.black{
  color: #777;
}
.collapse-enter-active,
.collapse-leave-active {
  transition: height 0.5s ease;
}
.collapse-enter,
.collapse-leave-to {
  height: 0;
  overflow: hidden;
}
.up-image-dialog{
  background-color: white;
  width: 1142px;
  height: 640px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  margin-top: -320px;
  left: 50%;
  margin-left: -571px;
  padding-top: 92px;
  box-sizing: border-box;
  .up-close{
    position: absolute;
    top: 18px;
    right: 17px;
    cursor: pointer;
  }
  .up-con{
    width: 713px;
    height: 346px;
    margin: 0 auto;
    .up{
      width: 713px;
      height: 346px;
      border: 1px dashed #B6B6B6;
      text-align: center;
      cursor: pointer;
      .up-icon{
        margin: 0 auto;
        margin-top: 138px;
        width: 57px;
        height: 57px;
      }
      img{
        width: 57px;
        height: 57px;
      }
      p{
        font-size: 12px;
        color: #444;
        margin-top: 26px;
      }
    }
    .dots{
      margin-top: 28px;
      .dots-li{
        margin-bottom: 14px;
        font-size: 14px;
        span{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #333;
          display: inline-block;
          margin-right: 5px;
        }
        &:nth-child(2){
          color: #FF5347;
        }
      }
    }
  }
}
.add-image {
  width: 316px;
  .contral {
    display: flex;
    align-items: center;
    height: 120px;
    justify-content: space-between;
    padding: 0 16px;
    &-list {
      text-align: center;
      width: 132px;
      height: 80px;
      border-radius: 12px;
      opacity: 1;
      border: 1px dashed #686868;
      &:hover{
        border: 1px dashed #ff5347;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-top: 13px;
        width: 32px;
        height: 32px;
      }
      span {
        color: #444444;
        font-size: 16px;
      }
    }
  }
  .image-main {
    .image-header {
      width: 316px;
      height: 36px;
      display: flex;
      align-items: center;
      background: #e2e2e2;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 0 20px;
      font-size: 16px;
      img {
        margin-left: auto;
        width: 16px;
      }
    }
    .image-con {
      display: flex;
      flex-direction: column;
      .image-list {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 20px;
        position: relative;
        cursor: pointer;
        &-icon {
          position: absolute;
          bottom: 18px;
          right: 20px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        &-left {
          img {
            width: 40px;
            height: 40px;
            object-fit:contain
          }
        }
        &-center {
          margin-left: 9px;
          .image-list-name {
            font-size: 16px;
            font-weight: bold;
            width: 120px;
          }
        }
      }
    }
  }
}
</style>
