<template>
  <div class="custom">
    <!-- 头部 -->
    <div class="custom-header">
      <div class="custom-header-logo">
        <img src="@/assets/images/custom/logo.png" alt="" @click="$router.push('/')">
      </div>
      <div class="custom-header-opera">
        <div class="custom-header-opera-back" @click="$router.push('/catalog')">
          <img src="@/assets/images/custom/back.png" alt="">
          <span>Back to Catalog</span>
        </div>
        <!-- <div class="custom-header-opera-btn custom-header-opera-branding">
          branding
        </div> -->
        <div v-if="isSubmit" class="custom-header-opera-btn custom-header-opera-submit2">
          <a-spin v-if="isSubmit" />
          <span style="margin-left: 5px">Submit</span>
        </div>
        <div v-else class="custom-header-opera-btn custom-header-opera-submit" @click="submit">
          <span style="margin-left: 5px">Submit</span>
        </div>
      </div>
    </div>
    <div class="custom-main">
      <!-- 侧边栏 -->
      <div class="fun">
        <div class="fun-img " :class="menuActived == 2 ? 'cur' : ''" @click="changeMenu(2)">
          <img v-show="menuActived == 1 || menuActived == 3" src="@/assets/images/sds/chizi.png" alt="">
          <img v-show="menuActived == 2" src="@/assets/images/sds/cizi-actived.png" alt="">
          <p>Product</p>
        </div>
        <div class="fun-img " :class="menuActived == 1 ? 'cur' : ''" @click="changeMenu(1)">
          <img v-show="menuActived == 2 || menuActived == 3" src="@/assets/images/sds/maozi.png" alt="">
          <img v-show="menuActived == 1" src="@/assets/images/sds/maozi_actived.png" alt="">
          <p>Layers</p>
        </div>
        <div class="fun-img " :class="menuActived == 3 ? 'cur' : ''" @click="changeMenu(3)">
          <img v-show="menuActived == 1 || menuActived == 2" src="@/assets/images/sds/tupian.png" alt="">
          <img v-show="menuActived == 3" src="@/assets/images/sds/tupian2.png" alt="">
          <p>Library</p>
        </div>
      </div>
      <!-- 图片展示区 -->
      <div v-show="menuActived == 1" class="image-contral">
        <div class="sku-title">{{ productInfo.english_name }}</div>
        <add-image :sdk="sdk" :oss-data="ossData" :design-info="designInfo" @resetImage="resetImage" />
      </div>
      <div v-show="menuActived == 2" class="skus">
        <div class="sku-title">{{ productInfo.english_name }}</div>
        <!-- <sku
          :skus="blankProduct.skuInfos"
          place-holder-prefix="Select A"
          :option1-name="blankProduct.blankProductInfo.skuOption1"
          :option1-type="blankProduct.blankProductInfo.skuOption1Type"
          :option2-name="blankProduct.blankProductInfo.skuOption2"
          :option2-type="blankProduct.blankProductInfo.skuOption2Type"
          :option3-name="blankProduct.blankProductInfo.skuOption3"
          :option3-type="blankProduct.blankProductInfo.skuOption3Type"
          @skuChange="onSkuChange"
        >
          <template v-slot:optionName="slotProps">
            <div v-if="slotProps.index === 1" class="font18 ff-ss fw-400 fc-333 d-flex">
              {{ slotProps.optionName }}
            </div>
            <span v-else class="font18 ff-ss">{{ slotProps.optionName }}</span>
          </template>
        </sku> -->
        <sku :sku-maps="blankProduct.skuMaps" :sku-infos="blankProduct.skuInfos" @setSkuArr="setSkuArr" @onSkuChange="onSkuChange" />
      </div>
      <div v-show="menuActived == 3" class="image-contral">
        <my-images ref="myImages" :sdk="sdk" @add="addImage" />
      </div>
      <!-- 画布区 -->
      <div class="canvas-main">
        <opera :sdk="sdk" :knife-info="knifeInfo" :dpi-info="dpiInfo" :sds-knife-set-info-list="blankProduct.sdsKnifeSetInfoList" />
        <div class="canvas-con">
          <knifeList :knife-array="knifeArray" :sds-layer-name-map-list2="blankProduct.sdsLayerNameMapList" @change="changeKnife" />
          <div id="designBox" />
        </div>
      </div>
      <div class="canvas-priview">
        <control :sdk="sdk" :design-info="designInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import 'sds-design-sdk/dist/fonts.css'
import addImage from './add_image/index.vue'
import myImages from './add_image/my-images.vue'
import opera from './components/opera.vue'
import Control from './control/index.vue'
import knifeList from './components/knifeList'
import sku from './sku/index'
import { fileSts } from '../../api/oss'
import { sdsAdd } from '../../api/sds'
import { SdsDesign } from 'sds-design-sdk'
import { productDetail } from '@/api/product'

export default {
  components: {
    myImages,
    addImage,
    opera,
    Control,
    knifeList,
    sku
  },
  data() {
    return {
      menuActived: 2,
      sdk: null,
      knifeArray: [],
      designInfo: {}, // 当前图层信息
      // oss信息上传图片用
      ossData: {},
      knifeInfo: {},
      productInfo: {},
      variants: [],
      blankProduct: {
        id: null,
        blankProductInfo: {},
        sdsKnifeSetInfoList: [],
        showImageList: [],
        skuInfos: [],
        skuMaps: [],
        skuTrees: [],
        shippingRateInfos: [],
        logisticsMethodInfos: [],
        detailImageList: [],
        blankDescToC: null,
        blankSpecMetric: null,
        blankSpecImperial: null,
        sdsLayerNameMapList: []
      },
      selectedSkuId: null,
      dpiInfo: {},
      isSubmit: false,
      prototypeGroupId: null,
      skuTidList: []
    }
  },
  mounted() {
    this.getProductData()
    this.getOssMsg()
  },
  methods: {
    saveDesigns() {
      console.log('skuinfo....', this.skuTidList)
      let arr = []
      if (this.skuTidList.length === 0) {
        for (const item of this.blankProduct.skuInfos) {
          arr.push(item.tid)
        }
      } else {
        arr = this.skuTidList
      }
      return new Promise(async resolve => {
        var rs = []
        for (const item of arr) {
          await this.changeDesign(item)
          var productId = await this.saveDesign()
          rs.push(productId)
        }
        console.log('save designs', rs)
        resolve(rs)
      })
    },
    setSkuArr(arr) {
      this.skuTidList = arr
    },
    changeDesign(id) {
      return new Promise(resolve => {
        this.sdk.switchVariantById(id, (data1) => {
          console.log('设计1...', data1)
          resolve()
        })
      })
    },
    saveDesign() {
      return new Promise(resolve => {
        this.sdk.saveDesign(async(res) => {
          console.log('保存设计', JSON.stringify(res))
          resolve(res.data.id)
        })
      })
    },
    // 初始化画布   1单刀版2多刀版
    initSds(val = 1, prototypeGroupId) {
      this.prototypeGroupId = prototypeGroupId
      this.sdk = new SdsDesign()
      this.sdk.init({
        appId: 'poim4vrvcjua1ow87bf4uqxoibg1l7ca',
        secret: 'mfmrv2yuemlnustvvk2m18ojah185x5exmt28f4xwao7nvuvukl2rcg6kgd3v4ffwf0048q5q4z18db49dv77v71p8cjnqbasxz3i41nlgvfw1gv161oy479fi09e8w8',
        timestamp: new Date().getTime(),
        language: 'en-US',
        productId: this.$route.query.id,
        previewContainerId: 'previewBox',
        previewSize: 264,
        designContainerId: 'designBox',
        prototypeGroupId: val === 2 ? prototypeGroupId : '',
        initCallback: (data2) => {
          this.getDesignFaces()
          setTimeout(() => {
            this.variants = this.sdk.getVariants()
            // this.sdk.switchVariantById(5385, () => {})
            console.log('this.variants...', this.variants)
            this.productInfo = this.sdk.getProductData()
            console.log('this.productInfo...', this.productInfo)
          }, 200)
        },
        designCallback: (data) => {
          this.designInfo = data
          this.countDPI()
        }
      })
    },
    async submit() {
      this.isSubmit = true
      var productIds = await this.saveDesigns()
      const { code, data } = await sdsAdd({
        blankProductId: this.$route.query.productId,
        sdsDesignProductIds: productIds
      })
      if (code === 200) {
        this.isSubmit = false
        this.callNewPage({
          path: '/mockups',
          query: {
            customProductId: data,
            productId: this.$route.query.productId
          }
        })
      }
    },
    async getProductData() {
      const { data } = await productDetail({
        id: this.$route.query.productId,
        blankProductInfo: true,
        skuInfos: true,
        skuMap: true,
        shippingRateInfos: true,
        skuTree: true
      })
      console.log('dddd....', data)
      this.blankProduct.blankProductInfo = data.blankProductInfo
      this.blankProduct.skuInfos = data.skuInfos
      this.blankProduct.skuMaps = data.skuMaps
      this.blankProduct.sdsKnifeSetInfoList = data.blankProductInfo.sdsKnifeSetInfoList
      this.blankProduct.sdsLayerNameMapList = data.blankProductInfo.sdsLayerNameMapList
      this.initSds(1, '')
    },
    // 修改sku
    onSkuChange(v) {
      console.log('切换sku...', v)
      let prototypeGroupId = null
      if (this.prototypeGroupId && this.prototypeGroupId != null) {
        prototypeGroupId = this.prototypeGroupId
      }
      this.sdk.switchVariantById(v, () => {
        this.knifeArray = this.sdk.getDesignFaces()
        this.knifeInfo = this.knifeArray[0]
        console.log('onSkuChange..', this.knifeArray)
      }, prototypeGroupId)
      this.selectedSkuId = v
    },
    // 获取OSS信息
    async getOssMsg() {
      const { data } = await fileSts()
      this.ossData = data
    },
    changeMenu(val) {
      this.menuActived = val
    },
    getDesignFaces() {
      this.knifeArray = this.sdk.getDesignFaces()
      this.knifeInfo = this.knifeArray[0]
      console.log('aaaa..', this.knifeArray)
    },
    addImage(id) {
      console.log('ru...', id)
      this.sdk.addMaterialById(id)
    },
    // 切换刀版
    changeKnife(item) {
      this.sdk.switchDesignFaceById(item.id)
      this.knifeArray = this.sdk.getDesignFaces()
      this.knifeInfo = item
      console.log('this.knifeInfi....', this.knifeInfo)
    },
    resetImage() {
      this.$refs.myImages.reset()
    },
    // 计算dpi(传的是当前图的大小)
    countDPI() {
      const _this = this
      const decoration = this.designInfo
      const actived = decoration.layers[decoration.index]
      const param = {}
      if (!actived) {
        param.dpi = ''
        param.dpiClass = '' // goods normal bad
        this.dpiInfo = param
        return
      }
      // 文字默认好
      if (actived.type === 'i-text') {
        param.dpi = 'High resolution'
        param.dpiClass = 'goods' // goods normal bad
        this.dpiInfo = param
        return
      }
      console.log('计算dip...', actived.type)
      // const objectWH = this.countWidthHeight(actived)
      const activedWidth = actived.width * actived.scaleX
      const activedHeight = actived.height * actived.scaleY
      console.log('activedWidth...', activedWidth)
      console.log('actived.scaleX...', actived.scaleX)

      // 图片宽度
      const goodMin = 150
      const goodMax = 299999

      const normalMin = 100
      const normalMax = 149
      let chicun = 0
      let dpi = 0
      console.log('_this.knifeInfo.width...', _this.knifeInfo.width)
      if (Number(_this.knifeInfo.width) > Number(_this.knifeInfo.height)) {
        chicun = _this.knifeInfo.width / 72
        dpi = activedWidth / chicun
      } else {
        chicun = _this.knifeInfo.height / 72
        dpi = activedHeight / chicun
      }
      console.log('dpi...', dpi)
      // if (type === 1) {
      //   dpi = objectWH.width / chicun
      // } else if (type === 2) {

      console.log('activedWidth...', activedWidth)
      // }
      param.dpiValue = dpi
      if (dpi >= goodMin && dpi <= goodMax) {
        param.dpi = 'High resolution'
        param.dpiClass = 'goods' // goods normal bad
      } else if (dpi >= normalMin && dpi <= normalMax) {
        param.dpi = 'Medium resolution'
        param.dpiClass = 'normal' // goods normal bad
      } else {
        param.dpi = 'Low resolution'
        param.dpiClass = 'bad' // goods normal bad
      }
      console.log('dpi....' + dpi)
      this.dpiInfo = param
      // return param
    },
    // 计算宽高
    countWidthHeight(e) {
      const bound = e.getBoundingRect()
      const param = {}
      param.height = bound.height
      param.width = bound.width
      console.log('countWidthHeight....', param)
      return param
    }
  }
}
</script>
<style>
.custom-header-opera-btn .ant-spin-dot-item{
  background-color: white;
}
.sdsDesignerTextModal__style-nE06y7ce .sdsDesignerModalWrap__style-cQtyzEhB .sdsDesignerModalContent__style-F1eliozr .sdsDesignerContentFooter__style-Q_4zq7vD .sdsDesignerConfirmBtn__style-TEb8JGfV{
  background: #FF5347 !important;
  border-color: #FF5347 !important;
}
.sdsDesignerTextModal__style-nE06y7ce .sdsDesignerModalWrap__style-cQtyzEhB .sdsDesignerModalContent__style-F1eliozr .sdsDesignerContentFooter__style-Q_4zq7vD .sdsDesignerConfirmBtn__style-TEb8JGfV:hover{
  background: #FF5347 !important;
  border-color: #FF5347 !important;
}
</style>
<style scoped lang="scss">

#designBox{
  width: 602px;
  margin: 0 auto;
  margin-top: 56px;
}
.custom {
  background-color: white;
  &-header {
    height: 79px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &-logo {
      padding-left: 19px;
      img {
        width: 160px;
        height: 51px;
      }
    }
    &-opera {
      margin-left: auto;
      display: flex;
      align-items: center;
      &-back {
        padding: 12px 20px;
        cursor: pointer;
        &:hover{
          background: #F5F5F5;
          border-radius: 4px;
        }
        img {
          width: 22px;
          height: 13px;
          cursor: pointer;
        }
        span {
          margin-left: 10px;
          color: #444444;
          font-size: 14px;
        }
      }
      &-btn {
        width: 128px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
      }
      &-branding {
        border-radius: 4px;
        border: 1px solid #ff5347;
        color: #ff5347;
      }
      &-submit {
        background: #ff5347;
        border-radius: 4px;
        font-weight: bold;
        margin-left: 16px;
        color: white;
        margin-right: 30px;
      }
      &-submit2 {
        background: rgb(255, 83, 71,0.5);
        border-radius: 4px;
        font-weight: bold;
        margin-left: 16px;
        color: white;
        margin-right: 30px;
      }
    }
  }
  &-main {
    display: flex;
    .fun {
      display: flex;
      flex-direction: column;
      width: 64px;
      background-color: #F5F5F5;
      height: calc(100vh - 79px);
      &-img {
        width: 64px;
        height: 64px;
        display: block;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        padding-top: 5px;
        box-sizing: border-box;
        img {
          width: 30px;
          height: 30px;
        }
      }
      .cur{
        background-color: white;
        p{
          color: #FF5347;
        }
      }
    }
    .image-contral {
      border-top: 0;
    }
    .canvas-main {
      position: relative;
      flex: 1;
      .canvas-con {
        position: relative;
        height: calc(100% - 64px);
        display: flex;
        background-color: #F4F4F4;
      }
    }
  }
  .canvas-priview {
    width: 324px;
    border-left: 1px solid #cbcbcb;
  }
  .skus {
    // border: 1px solid #c0c0c0;
    border-top: 0;
    width: 316px;
  }
}
.sku-title {
  color: #333;
  display: flex;
  align-items: center;
  width: 316px;
  height: 65px;
  font-size: 18px;
  padding: 11px 40px 11px 19px;
  box-sizing: border-box;
  font-weight: bold;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
