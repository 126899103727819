<template>
  <div>
    <div id="previewBox" ref="sdsPreviewBox">
      <img src="@/assets/images/sds/fangdajing.png" class="fdj" alt="" @click="openPreview">
    </div>
    <div v-if="activeInfo">
      <div v-if="activeInfo.type == 'image' || activeInfo.type == 'i-text'" class="image-opera">
        <div class="image-opera-header">
          <span>Attribute setting</span>
        <!-- <a-icon type="down" class="image-opera-header-icon" /> -->
        </div>
        <div class="image-opera-align">
          <div class="image-opera-align-list image-opera-align-left">
            <div class="image-opera-align-list-top">
              Align
            </div>
            <div class="image-opera-align-list-bottom">
              <!-- 左对齐 -->
              <a-tooltip placement="bottom">
                <template slot="title">
                  Align Left
                </template>
                <div class="image-opera-align-list-bottom-list zuoduiqi" @click="handAlign('left')" />
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template slot="title">
                  Align Vertically
                </template>
                <div class="image-opera-align-list-bottom-list cuizhijuzhong" @click="handAlign('horizontally')" />
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template slot="title">
                  Align Right
                </template>
                <div class="image-opera-align-list-bottom-list youduiqi" @click="handAlign('right')" />
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template slot="title">
                  Align Top
                </template>
                <div class="image-opera-align-list-bottom-list dingduiqi" @click="handAlign('top')" />
              </a-tooltip>
              <!-- 水平居中对齐 -->
              <a-tooltip placement="bottom">
                <template slot="title">
                  Align Horizontally
                </template>
                <div class="image-opera-align-list-bottom-list shuipingjuzhong" @click="handAlign('verticalCenter')" />
              </a-tooltip>
              <!-- 底对齐 -->
              <a-tooltip placement="bottom">
                <template slot="title">
                  Align Bottom
                </template>
                <div class="image-opera-align-list-bottom-list diduiqi" @click="handAlign('bottom')" />
              </a-tooltip>
            </div>
          </div>
          <div class="image-opera-align-list image-opera-align-right">
            <div class="image-opera-align-list-top">
              Flip
            </div>
            <div class="image-opera-align-list-bottom">
              <a-tooltip placement="bottom">
                <template slot="title">
                  Reflect Horizontally
                </template>
                <div class="image-opera-align-list-bottom-list shuipingfanzhuan" @click="handAlign('leftRight')" />
              </a-tooltip>
              <a-tooltip placement="bottom">
                <template slot="title">
                  Reflect Vertically
                </template>
                <div class="image-opera-align-list-bottom-list cuizhifanzhuan" @click="handAlign('topBottom')" />
              </a-tooltip>
            <!-- <img src="@/assets/images/sds/shuipingfanzhuan.png" @click="handAlign('leftRight')">
            <img src="@/assets/images/sds/cuizhifanzhuan.png" @click="handAlign('topBottom')"> -->
            </div>
          </div>
        </div>
        <div v-if="activeInfo != undefined && JSON.stringify(activeInfo).length !== 2">
          <image-opera v-if="activeInfo.type == 'image'" ref="imageOpera" :active-info="activeInfo" :sdk="sdk" />
          <font-opera v-if="activeInfo.type == 'i-text'" ref="textOpera" :active-info="activeInfo" :sdk="sdk" />
        </div>
      </div>
    </div>
    <maskDialog :show="previewDialog">
      <div class="priview">
        <div class="up-close" @click="previewDialog=false">
          <a-icon type="close" style="fontSize: 22px" />
        </div>
        <img :src="dataURL" alt="" width="640px" height="640px">
      </div>
    </maskDialog>
  </div>
</template>
<script>
import imageOpera from './components/image-opera.vue'
import fontOpera from './components/font-opera.vue'
import maskDialog from '../components/mask.vue'

export default {
  components: { imageOpera, fontOpera, maskDialog },
  props: {
    sdk: {
      type: Object,
      default: () => ({})
    },
    designInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      previewDialog: false,
      dataURL: '',
      color: '#ff0000',
      activeInfo: {} // 活动对象的信息
    }
  },
  watch: {
    designInfo(newValue, oldValue) {
      console.log('con...designInfo...', newValue)
      if (newValue) {
        if (newValue.layers.length !== 0) {
          this.activeInfo = newValue.layers[newValue.index]
        } else {
          this.activeInfo = {}
        }
        this.$forceUpdate()
      }
    }
  },
  methods: {
    openPreview() {
      this.previewDialog = true
      var canvas = document.getElementById('sds-previewBox')
      this.dataURL = canvas.toDataURL()
      // this.sdsPreviewBox = document.getElementsByClassName('previewBox__style-h4R6LToe')[0]
      console.log('sdsPreviewBox...', this.dataURL)
    },
    // 对齐方式
    handAlign(val) {
      if (val === 'left') {
        console.log('this.sdk...', this.sdk)
        this.sdk.sarto.alignLeft()
      }
      // 垂直居中
      if (val === 'verticalCenter') {
        this.sdk.sarto.verticle()
      }
      if (val === 'top') {
        this.sdk.sarto.alignTop()
      }
      if (val === 'right') {
        this.sdk.sarto.alignRight()
      }
      if (val === 'bottom') {
        this.sdk.sarto.alignBottom()
      }
      // 水平居中
      if (val === 'horizontally') {
        this.sdk.sarto.horizontal()
      }
      if (val === 'leftRight') {
        this.sdk.sarto.updateCommonFlip('x')
      }
      if (val === 'topBottom') {
        this.sdk.sarto.updateCommonFlip('y')
      }
    }
  }
}
</script>
<style >
.ant-tooltip-inner {
  background-color: #333;
}
.ant-tooltip-arrow::before {
  background-color: #333;
}

.folders-tooltip .folders-opera-list:last-child {
  color: white;
}
</style>
<style>
.previewBox__style-h4R6LToe .page__style-ytjYJfLG{
  position: absolute;
  top: 50%;
}
#sds-current-page{
  position: absolute;
  bottom: -93px;
  left: 18px;
}
</style>
<style scoped lang="scss">
.priview{
  background-color: white;
  width: 700px;
  height: 700px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  margin-top: -350px;
  left: 50%;
  margin-left: -350px;
  padding-top: 30px;
  box-sizing: border-box;
  .up-close{
    position: absolute;
    top: 18px;
    right: 17px;
    cursor: pointer;
  }
}
  #previewBox{
    width: 264px;
    height: 264px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #B5B5B5;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    position: relative;
  }
  #previewBox .fdj{
    position: absolute;
    right: 4px;
    bottom: 7px;
    width: 36px;
    height: 36px;
    z-index: 10;
    cursor: pointer;
  }
  .image-opera {
  &-header {
    border-top: 1px dashed #d0d0d0;
    margin-left: 31px;
    margin-right: 31px;
    height: 57px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: bold;
    }
    &-icon {
      margin-left: auto;
    }
  }
  &-pu {
    display: flex;
    align-items: center;
    padding-left: 34px;
    padding-right: 26px;
    &-list {
      margin-right: 26px;
      text-align: center;
      img {
        width: 47px;
        height: 47px;
      }
      p {
        color: #777777;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
  &-align {
    display: flex;
    padding-left: 29px;
    padding-right: 26px;
    &-list {
      &-top {
        color: #bababa;
        font-size: 12px;
      }
      &-bottom {
        display: flex;
        margin-top: 6px;
        &-list{
          width: 28px;
          height: 28px;
          margin-right: 6px;
          &.zuoduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/zuoduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/zuoduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.cuizhijuzhong{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/cuizhijuzhong.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/cuizhijuzhong.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.youduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/youduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/youduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.dingduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/dingduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/dingduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.shuipingjuzhong{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/shuipingjuzhong.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/shuipingjuzhong.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.diduiqi{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/diduiqi.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/diduiqi.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.shuipingfanzhuan{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/shuipingfanzhuan.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/shuipingfanzhuan.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.cuizhifanzhuan{
            width: 28px;
            height: 28px;
            background: url('../../../assets/images/custom/cuizhifanzhuan.png') no-repeat;
            background-size: 100% 100%;
            &:hover{
              background: url('../../../assets/images/sds/cuizhifanzhuan.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        img {
          width: 28px;
          height: 28px;
          margin-right: 4px;
          cursor: pointer;
        }
      }
    }
  }
  &-scale {
    display: flex;
    padding-left: 34px;
    padding-right: 26px;
    margin-top: 18px;
    margin-bottom: 20px;
    &-list {
      &-top {
        color: #bababa;
        font-size: 12px;
      }
      &-box {
        width: 121px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #777777;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding-left: 6px;
        box-sizing: border-box;
        &-img {
          width: 24px;
          height: 24px;
        }
        input {
          outline: none;
          margin-left: 5px;
          text-align: center;
          width: 40px;
        }
        &-color {
          width: 24px;
          height: 24px;
          background: #333333;
          border-radius: 4px;
        }
        &-bs {
          margin-left: auto;
          cursor: ew-resize;
          img {
            padding-right: 6px;
          }
        }
      }
    }
  }
}
</style>
