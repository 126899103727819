<template>
  <div>
    <div class="image-opera-scale">
      <div class="image-opera-scale-list">
        <div class="image-opera-scale-list-top">
          Scale
        </div>
        <div class="image-opera-scale-list-box">
          <img src="@/assets/images/custom/dingjiekuang.png" class="image-opera-scale-list-box-img">
          <input :value="counts(change.scale)" readonly type="text">
          <div class="image-opera-scale-list-box-btn">
            <img src="@/assets/images/sds/up.png" alt="" @click="onZoom('in')">
            <img src="@/assets/images/sds/down.png" alt="" @click="onZoom('out')">
          </div>
        </div>
      </div>
      <div class="image-opera-scale-list" style="margin-left: 15px">
        <div class="image-opera-scale-list-top">
          Rotate
        </div>
        <div class="image-opera-scale-list-box">
          <img src="@/assets/images/custom/xuanzhuan.png" class="image-opera-scale-list-box-img">
          <input :value="countDu(change.angle)" readonly type="text">
          <div class="image-opera-scale-list-box-btn">
            <img src="@/assets/images/sds/up.png" alt="" @click="onRotate('counterclockwise')">
            <img src="@/assets/images/sds/down.png" alt="" @click="onRotate('clockwise')">
          </div>
        </div>
      </div>
    </div>
    <div class="image-opera-header">
      <span>Pattern Layout</span>
      <!-- <a-icon type="down" class="image-opera-header-icon" /> -->
    </div>
    <div class="image-opera-pu">
      <a-tooltip placement="bottom">
        <template slot="title">
          Normal
        </template>
        <div class="image-opera-pu-list zhengchang" @click="handTiling('nomal')" />
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          Tile
        </template>
        <div class="image-opera-pu-list jichu" @click="handTiling('basic')" />
      </a-tooltip>
      <!-- <a-tooltip placement="bottom">
        <template slot="title">
          Vertical Tile
        </template>
        <div class="image-opera-pu-list hengjiangjiaocuo" @click="handTiling('half-brick')" />
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          Horizontal Tile
        </template>
        <div class="image-opera-pu-list hengxiangjiaocuo" @click="handTiling('half-drop')" />
      </a-tooltip> -->
    </div>
  </div>
</template>
<script>
// import { fabric } from 'fabric'
export default {
  props: {
    sdk: {
      type: Object,
      default: () => ({})
    },
    activeInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      change: {
        scale: 0,
        angle: 0,
        horizontal: 0,
        vertical: 0
      }
    }
  },
  watch: {
    activeInfo: {
      handler(newValue, oldValue) {
        this.change.scale = newValue.scaleX
        this.change.angle = newValue.angle
      },
      deep: true
    }
  },
  methods: {
    counts(val) {
      return Math.round(val * 100) + '%'
    },
    countDu(val) {
      return Math.round(val) + '°'
    },
    handTiling(val) {
      if (val === 'nomal') {
        this.sdk.sarto.clearGroup()
      } else {
        this.sdk.sarto.tiling(val)
      }
    },
    onZoom(val) {
      this.sdk.sarto.zoom(val)
    },
    onRotate(val) {
      this.sdk.sarto.updateCommonAngle(val)
    },
    handTitle() {
      this.isTitle = true
    },
    handCancelTitle() {
      this.isTitle = false
    }

  }
}
</script>
<style scoped lang="scss">
.image-opera {
  &-header {
    border-top: 1px dashed #d0d0d0;
    margin-left: 31px;
    margin-right: 31px;
    height: 57px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: bold;
    }
    &-icon {
      margin-left: auto;
    }
  }
  &-pu {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding-left: 29px;
    padding-right: 26px;
    &-list {
      margin-right: 26px;
      text-align: center;
      width: 47px;
      height: 47px;
      &.zhengchang{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/zhengchang.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/zhengchang2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.jichu{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/jichu.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/jichu2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.hengjiangjiaocuo{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/hengjiangjiaocuo.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/hengjiangjiaocuo2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.hengxiangjiaocuo{
        width: 47px;
        height: 47px;
        background: url('../../../../assets/images/sds/hengxiangjiaocuo.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../../../../assets/images/sds/hengxiangjiaocuo2.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      img {
        width: 47px;
        height: 47px;
      }
      p {
        color: #777777;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
  &-scale {
    display: flex;
    padding-left: 29px;
    padding-right: 26px;
    margin-top: 18px;
    margin-bottom: 20px;
    &-list {
      &-top {
        color: #bababa;
        font-size: 12px;
      }
      &-box {
        width: 121px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #777777;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding-left: 6px;
        box-sizing: border-box;
        &-btn{
          width: 30px;
          height: 30px;
          margin-left: auto;
          img{
            width: 26px;
            height: 14px;
            display: block;
          }
        }
        &-img {
          width: 24px;
          height: 24px;
        }
        input {
          outline: none;
          margin-left: 5px;
          text-align: center;
          width: 40px;
        }
        &-color {
          width: 24px;
          height: 24px;
          background: #333333;
          border-radius: 4px;
        }
        &-bs {
          margin-left: auto;
          cursor: ew-resize;
          img {
            padding-right: 6px;
          }
        }
      }
    }
  }
}
</style>
