import { render, staticRenderFns } from "./font-opera.vue?vue&type=template&id=294e06f6&scoped=true"
import script from "./font-opera.vue?vue&type=script&lang=js"
export * from "./font-opera.vue?vue&type=script&lang=js"
import style0 from "./font-opera.vue?vue&type=style&index=0&id=294e06f6&prod&lang=css"
import style1 from "./font-opera.vue?vue&type=style&index=1&id=294e06f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294e06f6",
  null
  
)

export default component.exports