<template>
  <mask-dialog :show="promDialog">
    <div class="prom">
      <div class="title">
        <span>Customized Guide</span>
        <a-icon type="close" :style="{'fontSize': '20px','marginLeft': 'auto','cursor':'pointer'}" @click="promDialog = false" />
      </div>
      <div class="prom-con">
        Clogs use manual laminating process, so deviation between products and mockups is a normal phenomenon, In addition, most mockups are customized according to size of EU42 , so there areslight differences when producing other sizes.
      </div>
      <div class="btns">
        <button class="btn" type="danger" @click="promDialog = false">
          I known
        </button>
      </div>
    </div>
  </mask-dialog>
</template>
<script>
import maskDialog from './mask.vue'
export default {
  components: {
    maskDialog
  },
  data() {
    return {
      promDialog: false
    }
  }
}
</script>
<style lang="scss" scoped>
.prom{
  width: 857px;
  height: 649px;
  background: #FFFFFF;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -293.5px;
  margin-left: -324.5px;
  .title{
    width: 857px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    padding-right: 20px;
    span{
      padding-left: 30px;
    }
  }
  .prom-con{
    padding: 20px 30px;
    p{
      font-size: 16px;
    }
  }
  .btns{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 63px;
    border-top: 1px solid #ddd;
    .btn{
      border: 0;
      width: 100px;
      height: 34px;
      background-color: #FF5347;
      margin-left: auto;
      color: white;
      border-radius: 4px;
      margin-right: 26px;
    }
  }
}
</style>
