<template>
  <div class="knife">
    <!-- <div class="knife-title">All areas</div> -->
    <div class="knife-con">
      <div class="knife-scroll">
        <div v-for="(item,index) in knifeArray2" :key="index" class="knife-list" @click="changeKnife(item)">
          <div class="knife-list-img " :class="item.selected ? 'actived' : ''">
            <img :src="item.thumbnail">
          </div>
          <p>{{ filterName(item.name) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    knifeArray: {
      type: Array,
      default: () => []
    },
    sdsLayerNameMapList2: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      knifeArray2: [],
      sdsLayerNameMapList: []
    }
  },
  watch: {
    knifeArray(newValue, oldValue) {
      console.log('hhh22...')
      this.knifeArray2 = newValue
    },
    sdsLayerNameMapList2(newValue, oldValue) {
      console.log('hhh22...')
      this.sdsLayerNameMapList = newValue
    }
  },
  created() {
    if (this.sdsLayerNameMapList2) {
      this.sdsLayerNameMapList = this.sdsLayerNameMapList2
    }
  },
  methods: {
    changeKnife(item) {
      this.$emit('change', item)
    },
    filterName(name) {
      for (const item of this.sdsLayerNameMapList) {
        if (item.name === name) {
          return item.showName
        }
      }
    }
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc; /* 设置滚动条滑块颜色 */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #F4F4F4; /* 设置滚动条背景颜色 */
}
</style>
<style scoped lang="scss">
.knife {
  // position: absolute;
  // top: 28px;
  // left: 40px;
  margin-left: 30px;
  margin-top: 56px;
  &-title {
    width: 69px;
    height: 30px;
    background: #E7E7E7;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 20px;
    margin-top: 28px;
    margin-left: 10px;
  }
  .knife-con {
    height: 600px;
    overflow-y: scroll;
    direction: rtl; /* 设置文本流从右向左 */
    .knife-scroll {
      direction: ltr;
      padding-left: 10px;
      .knife-list {
        margin-bottom: 30px;
        text-align: center;
        position: relative;
        &-img {
          position: relative;
          width: 60px;
          height: 60px;
          border-radius: 10px;
          overflow: hidden;
          &.actived{
            border: 2px solid #FFCAC6;
          }
          img {
            width: 60px;
            height: 60px;
          }
          .zhiliang {
            position: absolute;
            bottom: 3px;
            right: 3px;
            .good {
              width: 11px;
              height: 11px;
              background: #52c41a;
            }
            .none {
              width: 11px;
              height: 11px;
              background: #e7e7e7;
            }
            .bad {
              width: 16px;
              height: 16px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-size: 16px;
          text-align: left;
        }
      }
    }
  }
}
</style>
