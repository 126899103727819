<template>
  <div class="my-images" @mouseout="handleMouseOut">
    <a-row style="padding: 0 7px">
      <a-col v-for="(item,index) in imageList" :key="index" :span="8" class="align" style="position: relative;">
        <!--  @mouseover="handleMouseOver(index)" @mouseout="handleMouseOut(index)" -->
        <img :src="item.fileUrl" class="my-img" alt="" @click="handAddImage(item.tid)" @mouseover="handleMouseOver(index)">
        <div v-if="mouseOver == index" class="details">
          <img :src="item.fileUrl" alt="">
          <p>{{ item.fileName }}</p>
          <p>Dimensions:<span>{{ item.imageWidth }}</span>*<span>{{ item.imageHeight }}</span>px</p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { filePage } from '@/api/file'
export default {
  data() {
    return {
      imageList: [],
      // 分页
      page: {
        current: 1,
        size: 30,
        fileType: 4
      },
      mouseOver: -1
    }
  },
  created() {
    this.getImageList()
  },
  methods: {
    handleMouseOver(index) {
      console.log('????', index)
      this.mouseOver = index
    },
    handleMouseOut() {
      this.mouseOver = -1
    },
    reset() {
      this.page.current = 1
      this.imageList = []
      this.getImageList()
    },
    // 文件列表（分页）
    async getImageList() {
      const { data, code, msg } = await filePage({
        current: this.page.current,
        size: this.page.size,
        fileType: this.page.fileType
      })
      if (code === 200 && data.records) {
        this.imageList.push(...data.records)
      } else {
        this.$message.error(msg)
      }
    },
    handAddImage(id) {
      this.$emit('add', id)
    }
    // 删除图片
    // async delImage() {
    //   this.page.current = 1
    //   this.imgList = []
    //   const { code } = await delFile({ fileId: this.selectImageId })
    //   if (code === 200) {
    //     this.getImageList()
    //   }
    // },
  }
}
</script>
<style lang="scss" scoped>
.my-images{
  width: 316px;
  position: relative;
  .align{
    text-align: center;
    margin-top: 12px;
  }
  .my-img{
    width: 85px;
    height: 85px;
    object-fit: contain;
    &:hover .details{
      display: block;
    }
  }
  .details{
    position: absolute;
    bottom: -303px;
    left: 15px;
    padding: 20px;
    box-sizing: border-box;
    width: 240px;
    height: 293px;
    background: #FFFFFF;
    box-shadow: 0px 4px 13px 0px rgba(0,0,0,0.15);
    border-radius: 4px 4px 4px 4px;
    z-index: 10;
    background-color: white;
    img{
      width: 200px;
      height: 198px;
    }
    p{
      margin: 0;
      padding: 0;
      padding-top: 10px;
      font-size: 12px;
      text-align: left;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis; /* 显示省略号 */
      white-space: nowrap; /* 文字不换行 */
      span{
        color: #FF5347;
      }
    }
  }
}
</style>
