<template>
  <div style="position: relative;">
    <div class="opera">
      <!--   -->
      <div class="opera-btn" @mouseover="isShowMode = true">
        Switch templates
      </div>
      <!--  -->
      <div class="opera-main">
        <div class="opera-main-list">
          <img src="@/assets/images/sds/huitui.png" alt="" @click="opera('Undo')">
          <p>Undo</p>
        </div>
        <div class="opera-main-list">
          <img src="@/assets/images/sds/qianjin.png" alt="" @click="opera('Redo')">
          <p>Redo</p>
        </div>
        <div class="opera-main-list">
          <!-- <div class="colorpick">
          <colorPicker v-model="color" />
        </div> -->
          <input id="" v-model="bgColor" type="color" name="" class="colorpick" @input="setBackground">
          <img src="@/assets/images/sds/beijing.png" alt="">
          <p>Backgroud</p>
        </div>
        <div class="opera-main-list">
          <img src="@/assets/images/sds/ljt.png" alt="" @click="opera('Reset')">
          <p>Reset</p>
        </div>
        <div class="opera-main-list">
          <img src="@/assets/images/sds/fuzhi.png" alt="" @click="opera('Copy')">
          <p>Copy</p>
        </div>
        <div class="opera-main-list">
          <img src="@/assets/images/sds/fangda2.png" alt="" @click="opera('Fill')">
          <p>Fill</p>
        </div>
        <div class="opera-main-list">
          <img src="@/assets/images/sds/fangda.png" alt="" @click="opera('Ada')">
          <p>Adapt</p>
        </div>
        <div class="opera-main-list">
          <img src="@/assets/images/sds/gantanhao.png" alt="" @click="opera('Prom')">
          <p>Prompt</p>
        </div>
      </div>
      <div class="opera-recom">
        <p v-if="knifeInfo" style="margin-top: 12px">Recommended: <span>{{ knifeInfo.width }}px*{{ knifeInfo.height }}px</span></p>
        <p>Print quality: <span :class="dpiInfo.dpiClass">{{ dpiInfo.dpi }}</span></p>
      </div>
      <promDialog ref="prom" />
    </div>
    <div v-show="isShowMode" class="opera-switch" @mouseover="isShowMode = true" @mouseout="isShowMode = false">
      <div v-for="(item, index) in sdsKnifeSetInfoList2" :key="index">
        <div class="opera-switch-title">
          {{ item.name }}
        </div>
        <div v-for="(item2, index2) in item.knifes" :key="index2+item2.id" class="opera-switch-list">
          <div class="opera-switch-list-li" :class="item2.id == designMode ? 'opera-switch-list-li-set':'opera-switch-list-li-default'" @click="changeMode(item2.id)">
            <div class="opera-switch-list-li-zw">
              <img v-if="designMode == item2.id" src="@/assets/images/sds/xuanzhong.png" alt="">
              <img v-if="designMode != item2.id" src="@/assets/images/sds/weixuanzhong.png" alt="">
            </div>
            {{ item2.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import promDialog from './promDialog.vue'
export default {
  components: {
    promDialog
  },
  props: {
    sdk: {
      type: Object,
      default: null
    },
    knifeInfo: {
      type: Object,
      default: null
    },
    dpiInfo: {
      type: Object,
      default: null
    },
    sdsKnifeSetInfoList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowMode: false,
      designMode: 1,
      bgColor: 'rgb(255,255,255)',
      sdsKnifeSetInfoList2: []
    }
  },
  watch: {
    sdsKnifeSetInfoList(newValue, oldValue) {
      this.sdsKnifeSetInfoList2 = newValue
    }
  },
  created() {
    if (this.sdsKnifeSetInfoList) {
      this.sdsKnifeSetInfoList2 = this.sdsKnifeSetInfoList
    }
  },
  methods: {
    handleMouseover() {
      console.log('hhhh')
      this.isShowMode = true
    },
    changeMode(val) {
      this.designMode = val
      this.sdk.remove()
      this.$parent.initSds(2, val)
    },
    duplicateHorizontally() {
      this.$emit('duplicateHorizontally')
    },
    setBackground(val) {
      console.log('setBackground....', val)
      console.log('颜色变化....', this.bgColor)
      const bg = this.bgColor
      this.sdk.sarto.setBackground(bg)
    },
    opera(val) {
      if (val === 'Undo') {
        this.sdk.sarto.undo()
      }
      if (val === 'Redo') {
        this.sdk.sarto.redo()
      }
      if (val === 'Reset') {
        this.sdk.sarto.clear()
      }
      if (val === 'Copy') {
        this.sdk.sarto.copy()
      }
      if (val === 'Fill') {
        this.sdk.sarto.updateImageLimit('fill')
      }
      if (val === 'Ada') {
        this.sdk.sarto.updateImageLimit('fit')
      }
      if (val === 'Prom') {
        this.$refs.prom.promDialog = true
      }
    }
  }
}
</script>
<style >
.folders-tooltip .ant-tooltip-inner {
  background-color: white;
}
.folders-tooltip .ant-tooltip-arrow::before {
  background-color: white;
}
.folders-tooltip .folders-opera-list {
  color: #ff9a40;
  height: 39px;
  line-height: 39px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.folders-tooltip .folders-opera-list:last-child {
  color: #ff5347;
}
</style>
<style scoped lang="scss">

.opera {
  // width: 1217px;
  height: 64px;
  position: relative;
  display: flex;
  &-btn{
    width: 133px;
    height: 36px;
    background: #FF5347;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 36px;
    position: relative;
    text-align: center;
    z-index: 101;
    margin-left: 16px;
    margin-top: 14px;
    cursor: pointer;
  }
  &-switch{
    padding: 0px 14px;
    padding-bottom: 17px;
    background-color: white;
    position: absolute;
    margin-top: 10px;
    left: 15px;
    z-index: 10;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.15);
    &-title{
      font-weight: bold;
      color: #333;
      font-size: 14px;
      padding-top: 17px;
    }
    &-list{
      display: flex;
      align-items: center;
      margin-top: 8px;
      &-li{
        width: 273px;
        height: 44px;
        line-height: 44px;
        display: flex;
        align-items: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #D9D9D9;
        &-text{
          margin-left: 12px;
          margin-right: 18px;
          cursor: pointer;
        }
        &-default{
          color: #999999;
        }
        &-set{
          border: 1px solid #FF5347;
        }
        &-zw{
          width: 38px;
          text-align: center;
          img{
            width: 16px;
            height: 16px;
          }
        }
      }

    }
  }
  &-main {
    background-color: white;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    justify-content: center;
    height: 64px;
    z-index: 10;
    &-line {
      width: 0px;
      height: 34px;
      opacity: 1;
      border: 1px solid #ebebeb;
      margin: 0 10px;
    }
    &-list {
      width: 64px;
      height: 64px;
      font-size: 11px;
      color: #777;
      position: relative;
      img {
        width: 40px;
      }
      .colorpick{
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        text-align: center;
        opacity: 0;
      }
    }
  }
  &-recom{
    margin-left: auto;
    font-size: 14px;
    position: relative;
    z-index: 11;
    margin-right: 24px;
    p{
      color: #777;
      margin: 0;
      span{
        color: #444;
        &.goods {
          color: #52c41a;
        }
        &.bad {
          color: red;
        }
      }
    }
  }
  &-align {
    display: flex;
    &-list {
      width: 80px;
      text-align: center;
      img {
        width: 40px;
        height: 38px;
      }
      p {
        word-break: keep-all;
        font-size: 12px;
        color: #777;
        margin-top: 7px;
      }
    }
  }
}
</style>
